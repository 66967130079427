
#navigationMenu{
    display: block;
}

/* .k-widget{
    font-size: 16px;
} */

.k-grid-header {
  display: none!important;
}

.k-grid{
  color:#212529!important;
}

.k-grid-toolbar{
  background-color: #fff;
}

.k-grid {
    border-color: transparent;
    color: #656565;
    background-color: #fff;
    background-clip: padding-box;
}
.k-grid-content {
    width: 100%;
    min-height: 0;
    overflow: auto;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    flex: 1 1;
}

.k-grid tr.k-alt {
    background-color: transparent;
}
.k-combobox .k-select {
    border-color: #2E4F79;
    color: #2E4F79;
}

.k-grid td {
    padding: 8px 6px;
    /* border-style: solid; */
    border-color: inherit;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none
  
}

.k-grid th{
    padding: 8px 6px;
    /* border-style: solid; */
    border-color: inherit;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none
}

.k-grid a.icon-clr-blue{
    color:#007bff;
}

.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {
    color: black;
    background-color: transparent;
}

.k-grid tbody tr.txt-clr-blue:hover{
    color: #007bff;
}

.k-grid tr {
    padding: 8px 12px;
    border-width: 0 0 1px 1px;
    white-space: nowrap;
    border: none;
}

.grn-bg-icon {
    display: inline-block;
    width: 33px;
    height: 33px;
    background-color: #187B87;
    border-radius: 100px;
    line-height: 33px;
    color: #fff;
    text-align: center;
    margin-left: 16px;
}

@media (min-width: 320px) and (max-width: 667px) {
    .k-widget{
        font-size: 12px;
    }

    .checkmark-R {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: #fff;
        position: absolute;
        top: -1px;
        left: -4px;
    
    }


}


