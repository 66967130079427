@import url(https://fonts.googleapis.com/css?family=Open+Sans);
/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.errorMessage {
    color: white;
    font-size: 12px;
    /* margin-top: 26px; */
    /* background-color: white; */
}


@media (min-width: 320px) and (max-width: 667px) {
    .rememberMeCheck{
        top: -1px;
        left: -6px;
    }
    /* .k-loading-image {
        background-image: url('../../assets/images/805.gif');
        background-repeat: no-repeat;
        background-size: 50px 50px;
        z-index: 15000000 !important;
    } */
} 

#root {
    font-family: 'Open Sans' !important;
}

body, html {
    font-family: 'Open Sans' !important;

}



.font-fourteen-px {
    font-size: 14px;
}

.font-medium {
    font-size: 14px;
}

.navbar-user-crcl {
    width: 37px;
    height: 37px;
    background-color: #fff;
    border-radius: 37px;
    line-height: 37px;
    text-align: center;
    font-size: 14px;
    align-items: center;
    margin-top: 5px;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
}

.dropdown-toggle::after {
    display: inline-block;
    /* margin-left: .255em; */
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}


.dropdown-menu {
    position: absolute;
    top: 124%;
    left: 5px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0px;
    margin: .125rem 0 0;
    font-size: 12px;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0px;
    border: none;
}


.btn-dropdown {
    display: inline-block;
    color: #4987EC;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-dropdown-btn {
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    /* padding: .375rem .75rem; */
    font-size: 1rem;
    line-height: 1.5;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-dropdown:focus {
    outline: 0;
}

.bg-footer {
    background-color: #2999A6;
    font-size: 14px;
    color: white;
    width: 100%;
    background: #292929 !important;
    color: white;
    text-align: center;
    /*padding: 1.5rem;*/
    padding: 1rem;
    margin-top: auto !important;
}

.bg-summer-sky {
    background-color: #2999A6;
    color: white;
    height: 30px;
    line-height: 30px;
}

.bg-light-blue {
    background-color: #F2F7FF;
    color: #484848;
}

.font-size-big {
    font-size: 22px;
}


.gradient {
    background-image: -webkit-gradient(linear, left top, right top, from(#11346e), color-stop(#095795), color-stop(#0673ae), color-stop(#088caa), color-stop(#068995), to(#008787));
    background-image: -webkit-linear-gradient(left, #11346e, #095795, #0673ae, #088caa, #068995, #008787);
    background-image: linear-gradient(to right, #11346e, #095795, #0673ae, #088caa, #068995, #008787);
}

.shadow {
    box-shadow: 0 .3rem 1rem rgba(0,0,0,0.10) !important;
}

.shadow-login {
    box-shadow: 0 .3rem 1rem rgba(0,0,0,0.10) !important;
    width: auto;
    height: auto;
    /* display: flex;
    justify-content: center; */
}

.relative {
    /* margin-top: 10px; */
    position: relative;
    /* background-color: rgb(255, 255, 255); */
    width: 383px;
    height: 516px;
    color: #ffffff;
    background-image: url(/static/media/bg_ringo_login.3d7836a2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    background-position: initial;
    margin: 0px 5px 0px 5px;
}

.ringo-logo {
    height: 78px;
    width: 157px;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: transparent !important;
    background-clip: padding-box;
    border: 1px solid #109DD2;
    border-radius: 3px;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



.position-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

/* .footer {
    background-color:#2999A6;
    text-align: center;
    color: #fff;
    width: 100%;
    font-size: 14px;

} */
.Charcoal-black {
    color: #484848 !important;
}

.navText {
    color: #fff !important;
}

.grainsboro-clr {
    color: #A3A3A3 !important;
}

.txt-clr {
    color: #2999A6 !important;
}

.txt-clr-blue {
    color: #4987EC;
}

.txt-dar-blue {
    color: #2E4F79;
}

.txt-clr-green {
    color: #2999A6;
    line-height: 45px;
}

.txt-clr-green-select {
    color: #2999A6;
    line-height: 37px;
}



.btn:hover {
    color: #fff;
    text-decoration: none;
}

.btn {
    display: inline-block;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 4px;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



    .btn.focus, .btn:focus {
        outline: 0;
        box-shadow: none;
    }

button:focus {
    outline: 0px;
    outline: 0px;
}

/* .dropdown-menu:hover {
    display: block;
    position: absolute;
    transform: translate3d(44px, -172px, -17px);
    top: 0px;
    left: 0px;
    will-change: transform;
} */


.button {
    color: white;
    /* max-width: 250px; 
    min-width: 200px;     */
    height: 40px;
}

.button-new {
    color: white;
    /* max-width: 250px; 
    min-width: 200px;     */
    height: auto;
}

.button-report {
    background-color: #109DD2;
}

.button-bg-blue {
    background-color: #109DD2;
}

.button-report:hover {
    background-color: #107FA8;
}

.button-approved {
    background-color: #2999A6;
    font-size: 14px;
}

.button-bg-blue-clr {
    background-color: #109DD2;
}

.button-signin {
    width: 100%;
    max-width: 334px;
    min-width: 280px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    color: #2E4F79;
    font-size: 16px;
    text-align: center;
}

    .button-signin:hover {
        color: rgb(27, 86, 163);
    }


.button-approved:hover {
    background-color: #187B87;
}


.button-print:hover {
    background-color: #187B87;
}

.button-print {
    text-align: center;
    background-color: #109DD2;
}

.bg-export-icon {
    width: 30px;
    height: 30px;
    background-color: #2999A6;
    border-radius: 100px;
    line-height: 30px;
    text-align: center;
    color: white;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.card-ts-bg-clr {
    background-color: #F2F7FF;
}

.card-ts-bg-green {
    background-color: #F6FCFC;
}

.export-to-excel {
    border-right: 1px solid #d9d9d9;
}

.grn-bg-icon {
    display: inline-block;
    width: 30px !important;
    height: 30px !important;
    background-color: #187B87;
    border-radius: 30px !important;
    line-height: 30px !important;
    color: #fff;
    text-align: center;
}

.grn-bg-icon-checkbox {
    display: inline-block;
    /* width: 25px;
    height: 28px; */
    /* line-height: 33px; */
    text-align: center;
}

.icon-clock-dollar {
    width: 30px;
    height: 30px;
    text-align: center;
}

.icon-clr-blue {
    color: #007bff;
    /* text-align: center;
    cursor: pointer; */
}

/* START-CHECK-BOX-CSS */

.container-R {
    display: block;
    position: relative;
    padding-left: 25px;
    /*margin-bottom: 12px;*/
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


    .container-R input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }


.checkmark-R {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #4987EC;
    border-radius: 2px;
}



.container-R:hover input ~ .checkmark-R {
    background-color: #fff;
}




.container-R input:checked ~ .checkmark-R:after {
    display: block;
}

/* END-CHECK-BOX-CSS */

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F2F7FF;
}

.th {
    background-color: #2999A6;
    color: white;
}

.table tbody + tbody {
    border-top: 1px solid #691c1c;
}

.table thead th {
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: baseline;
}

.table th {
    border-top: 0px solid #dee2e6;
}

.table td, .table th {
    border-top: 1px solid none;
}

.table {
    width: 100%;
    margin-bottom: 0px;
    color: #212529;
}

.main {
    width: 100%;
    margin: 50px auto;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.btn-secondary {
    color: #109DD2;
    background-color: #ffffff;
    border-color: #109DD2;
}

    .btn-secondary:hover {
        color: #109DD2;
        background-color: #ffffff;
        border-color: #109DD2;
    }

    .btn-secondary.focus, .btn-secondary:focus {
        color: #109DD2;
        background-color: #ffffff;
        border-color: #109DD2;
    }

    .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
        color: #109DD2;
        background-color: #ffffff;
        border-color: #109DD2;
    }

.flex-container {
    display: flex;
    flex-wrap: nowrap;
    background-color: #F2F7FF;
}

    .flex-container > div {
        width: 100%;
        text-align: center;
    }

.check {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #4987EC;
    border-radius: 2px;
    top: -1px;
    left: -5px;
}

.search-icon {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
    border: 1px solid #109DD2;
    border-top-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
}

.button-regular {
    background-color: #109DD2;
    text-align: center;
    font-size: 14px;
}

    .button-regular:hover {
        background-color: #1291C1;
        text-align: center;
        font-size: 14px;
    }


.search-button-focus:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.search-button-focus:focus, .search-button-focus.focus {
    box-shadow: none;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
}

.timesheetMenu {
    min-width: 13rem;
}

.nav-span {
    margin-left: 0px;
}

#searchCard, #searchCardMob {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 100;
}

#searchCardMob {
    padding-left: 8px;
    padding-right: 8px;
    top: 30px;
}

#headerMenu {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 10000;
}

/* #timesheetDataGrid tr:nth-child(even) .card-ts-bg-clr{
    background-color: #F6FCFC;
} */

td.positionItemRow div.card-ts-bg-clr:nth-child(even) {
    background-color: #F6FCFC;
}

hr {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-link {
    padding: 0;
}

p {
    margin-top: 0;
    margin-bottom: 4px;
}

.button-reject {
    background-color:#D21031;
    font-size: 14px;
    min-width: 100px;
}
.button-reject:hover {
    background-color :#C1122F;
}

.button-reject-new {
    background-color:#D21031;
    font-size: 14px;
    min-width: auto;
}
.button-reject-new:hover {
    background-color :#C1122F;
}

.dashboard-bl {
    border-bottom: 4px solid rgb(228, 4, 41);
    padding-right: 10px;
    padding-bottom: 8px;
    margin-bottom: 0px;
    display: inline-block;
    position: absolute;
    top: 28px;
    left: 86px;
   
}


.placeholder::-webkit-input-placeholder {
    color: #fff !important;
    font-style: italic;
}


.placeholder::-moz-placeholder {
    color: #fff !important;
    font-style: italic;
}


.placeholder::-ms-input-placeholder {
    color: #fff !important;
    font-style: italic;
}


.placeholder::placeholder {
    color: #fff !important;
    font-style: italic;
}

.placeholder-brd {
    border: 1px solid white;
}

.placeholder-brd {
    border: 1px solid white;
}
.form-control:focus {
    /* color: #495057; */
    /* background-color: #fff; */
    border-color: #109DD2;
    outline: 0;
    box-shadow: none;
}

.search-icon {
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
    border: 1px solid #109DD2;
    border-top-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 4px;
}

.loaderContainer {
    border: 0px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
    border-radius: 4px;
    background-color: white;
    display: flex;
    padding: 16px;
    width: auto;
    height: auto;
    text-align: center;
    margin-left: 0px;
    margin-top: 10px;
}

.circleClass {
    height: 56px;
    width: 56px;
    border-radius: 50%;
}

.line {
    width: 60%;
    height: 20px;
    align-self: center;
    margin-left: 16px;
    border-radius: 8px;
    display: block;
    margin-bottom: 16px;
    margin-top: 2px;
}

div[class*="Shimmer-shimmer"] {
    /* background-image: linear-gradient(to right,#eeeeee 40%,grey 50%,#eeeeee 60%) !important; */
    -webkit-animation: shimmerAnim 1.3s infinite linear;
            animation: shimmerAnim 1.3s infinite linear;
}

.nextShimmerDiv {
    margin-top: 20px;
}

.inline-block {
    display: inline-block;
}

.placeholder ::-webkit-input-placeholder {
    font-style: italic;
}

.placeholder ::-moz-placeholder {
    font-style: italic;
}

.placeholder ::-ms-input-placeholder {
    font-style: italic;
}

.placeholder ::placeholder {
    font-style: italic;
}

.positionItemRow {
    background-color: none;
    margin-bottom: 2px;
    
}

.dropdown-item.active, .dropdown-item:active {
    color: #109DD2;
    text-decoration: none;
    background-color: #F6FCFC;
    outline-color: transparent !important;
}

.underLineText {
    text-decoration: underline;
}
.dropdown-menu-right-submit {
    left: -11px;
    min-width: 11.5rem;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: white;
    text-decoration: none;
    background-color:  #0c4c89;
}

.dropdown-item:hover > a, .dropdown-item:focus > a {
    color: white;
}

@media (min-width: 320px) and (max-width: 667px) {
    .relative {
        height: 536px;
        margin-top: 0.5rem;
    }

body{
    /* font-size: .8rem; */
    font-size: 12px;
    background-color: #FCFCFC;
}
    .btn {
        display: inline-block;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 4px;
        -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .button {
        color: white;
        /* max-width: 200px;  */
        /* min-width: 136px;  */
        min-width: auto;
        height: auto;
    }

    .button-print:hover {
        background-color: #187B87;
    }

    .button-print {
        border-radius: 3px;
        line-height: 20px;
        text-align: center;
        background-color: #187B87;
    }


    .button-report {
        background-color: #109DD2;
        text-align: center;
        font-size: 12px;
    }

        .button-report:hover {
            background-color: #107FA8;
        }

    .button-approved {
        background-color: #2999A6;
        text-align: center;
        font-size: 12px;
    }

        .button-approved:hover {
            background-color: #187B87;
        }


    /* .btn:hover {
        color: #2E4F79;
        text-decoration: none;
        background-color: #fff;
    } */

    .button-signin {
        width: 100%;
        max-width: 334px;
        min-width: 280px;
        height: 40px;
        background-color: rgb(255, 255, 255);
        color: #2E4F79;
        font-size: 16px;
        text-align: center;
    }

        .button-signin:hover {
            color: rgb(27, 86, 163);
        }

.k-widget{
    font-size: 12px;
}
.dropdown-menu:hover -signin {
    width: 100%;
    max-width: 334px; 
    min-width: 257px;
    height: 45px;
    background-color:rgb(255, 255, 255);
    color: #2E4F79;
    font-size: 14px;
    text-align: center;
   
}
.dropdown-menu:hover -signin:hover {
    color: rgb(27, 86, 163);
   
}

    .dropdown-menu-mobile {
        padding: 0px;
    }

    ::-webkit-input-placeholder {
        color: #fff !important;
    }

    ::-moz-placeholder {
        color: #fff !important;
        font-style: italic !important;
    }

    ::-ms-input-placeholder {
        color: #fff !important;
        font-style: italic !important;
    }

    ::placeholder {
        color: #fff !important;
        font-style: italic !important;
    }

    .for-mobile::-webkit-input-placeholder {
        color: #BEBEBE !important;
    }

    .for-mobile::-moz-placeholder {
        color: #BEBEBE !important;
    }

    .for-mobile::-ms-input-placeholder {
        color: #BEBEBE !important;
    }

    .for-mobile::placeholder {
        color: #BEBEBE !important;
    }

    .bg-menu-blue {
        background-color: #F2F7FF;
        height: 55px;
        line-height: 38px;
    }

    .bg-menu-white {
        background-color: white;
        height: auto;
        line-height: auto;
    }

    .border-menu-hr {
        border-bottom: 1px solid #DEDEDE;
    }

    .navbar-user-crcl {
        width: 25px;
        height: 25px;
        background-color: #fff;
        border-radius: 25px;
        line-height: 25px;
        text-align: center;
    }

    .active-icon {
        color: #109DD2;
    }

    .export-to-excel {
        border-right: none;
    }


    .navbar-dark .navbar-toggler-icon {
        background-image: url(/static/media/bars-regular.a09aab76.svg);
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        content: "";
        background: no-repeat center center;
        background-size: 100% 100%;
        line-height: 20px;
    }

    .navbar-dark .navbar-toggler {
        color: #ffffff;
        border-color: transparent !important;
    }

    .menu-logo {
        position: relative;
        top: 321px;
        left: 21px;
    }

    .shadow-login {
        box-shadow: none !important;
        width: 383px;
        height: auto;
    }

    .form-control {
        display: block;
        width: 100%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        background-color: transparent !important;
        background-clip: padding-box;
        border: 1px solid #109DD2;
        border-radius: 4px;
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .main {
        width: 100%;
        margin: 10px auto;
    }

    /* Bootstrap 4 text input with search icon */

    .has-search .form-control {
        padding-left: 2.375rem;
    }

    .has-search .form-control-feedback {
        position: absolute;
        z-index: 2;
        display: block;
        width: 2.375rem;
        height: 2.375rem;
        line-height: 2.375rem;
        text-align: center;
        pointer-events: none;
        color: #aaa;
    }

    .btn-secondary {
        color: #109DD2;
        background-color: #ffffff;
        border-color: #109DD2;
    }

    .placeholder-mobile::-webkit-input-placeholder {
        color: #BEBEBE !important;
        font-style: italic;
    }

    .placeholder-mobile::-moz-placeholder {
        color: #BEBEBE !important;
        font-style: italic;
    }

    .placeholder-mobile::-ms-input-placeholder {
        color: #BEBEBE !important;
        font-style: italic;
    }

    .placeholder-mobile::placeholder {
        color: #BEBEBE !important;
        font-style: italic;
    }

    .checkmark-R-R {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: #fff;
        /* border: 1px solid #4987EC; */
        position: absolute;
        top: -1px;
        left: -6px;
    }

    .check {
        top: -1px;
        left: -6px;
    }

    .search-icon {
        background-color: transparent;
        box-shadow: none;
        border-color: transparent;
        border: 1px solid #109DD2;
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 4px;
    }

    .button-regular {
        background-color: #109DD2;
        text-align: center;
        font-size: 12px;
    }

        .button-regular:hover {
            background-color: #1291C1;
            text-align: center;
            font-size: 12px;
        }

    .search-button-focus:not(:disabled):not(.disabled):active:focus {
        box-shadow: none;
    }

    .search-button-focus:focus, .search-button-focus.focus {
        box-shadow: none;
    }

    .form-control:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: none;
    }

    .active-menu {
        background-color: #F2F7FF;
        height: auto;
        line-height: auto;
    }

    .navText {
        color: #484848 !important;
    }

    .nav-span {
        margin-left: 1.5rem;
    }

    .grn-bg-icon {
        margin-left: 0px !important;
        display: inline-block;
        width: 25px !important;
        height: 25px !important;
        background-color: #187B87;
        border-radius: 25px !important;
        line-height: 25px !important;
        color: #fff;
        text-align: center;
    }

    div[class*="Shimmer-shimmer"] {
        background-image: -webkit-gradient(linear,left top, right top,color-stop(40%, #eeeeee),color-stop(50%, grey),color-stop(60%, #eeeeee)) !important;
        background-image: -webkit-linear-gradient(left,#eeeeee 40%,grey 50%,#eeeeee 60%) !important;
        background-image: linear-gradient(to right,#eeeeee 40%,grey 50%,#eeeeee 60%) !important;
        -webkit-animation: shimmerAnim 1.8s infinite linear;
                animation: shimmerAnim 1.8s infinite linear;
    }

    .searchText {
        font-size: 12px;
    }

    .check {
        height: 20px;
        width: 20px;
    }

    .mobileBlock {
        display: block;
    }

    hr {
        margin-top: 5px;
        margin-bottom: 5px;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .navbar {
        height: 45px;
        line-height: 45px;
        padding: 0px;
    }

    .signOut {
        margin-top: 13px;
        margin-right: 7px;
    }
    /* .container {
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: auto;
    margin-left: auto;
} */
.font-bold{
    font-size: 16px;
}
.font-regular {
    font-size: 13px;

}
.font-small{
    font-size: 12px;
}
.font-extra-bold{
    font-size: 18px;
}

    p {
        margin-top: 0;
        margin-bottom: 4px;
    }
    .nav-title-mobile{
        line-height: normal;
        line-height: initial;
        }
        .font-exrtasmall{
        font-size: 9px;
        }
        .dashboard-bl {
            border-bottom: 2px solid #58E686;
            padding-right: 10px;
            padding-bottom: 8px;
            margin-bottom: 0px;
            display: inline-block;
            position: absolute;
            top: 35px;
            left: 86px;
            border: 4px solid;
        }
        .text-overflow{
            text-overflow: ellipsis;
                max-width: 146px;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
            }
            
            .loaderContainer {
                border: 0px solid rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
                border-radius: 4px;
                background-color: white;
                display: flex;
                padding: 16px;
                width: auto;
                height: auto;
                text-align: center;
                margin-left: 0px;
                margin-top: 10px;
            }
        

}



@media (max-width: 992px) {
    .navbar-collapse {
        position: absolute;
        top: 46px;
        right: 100%;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 15px;
        width: 80%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        display: block;
        height: 100vh;
    }

        .navbar-collapse.navbar-collapse.show {
            height: 100vh !important;
            margin-right: 50%;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            display: block;
            background-color: rgb(255, 255, 255);
            z-index: 1000;
        }

        .navbar-collapse.show {
            right: -25%;
        }
}


@media (min-device-width : 768px) and (max-device-width : 1023.5px){
 
    .dropdown-menu-mobile {
        padding: 0px;
        position: absolute !important;
    }

    .timesheetTblGrid{
        height: 750px !important;
    }

  
    .navbar-dark .navbar-toggler-icon {
        background-image: url(/static/media/bars-regular.a09aab76.svg);
    }
    .navbar-dark .navbar-toggler {
        color: rgba(255, 255, 255, 0.5);
        border-color: transparent !important;
    }
    .navText {
        color: #484848 !important;
    }
    .active-icon {
        color: #109DD2;
    }
    .active-menu {
        background-color: #F2F7FF;
        /* height: 35px; */
        /* line-height: auto; */
        /* line-height: 35px;
        width: 560px; */
    }
    .navbar-collapse {
        position: absolute;
        top: 56px;
        right: 100%;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 15px;
        width: 80%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        display: block;
        height: 100vh;
    }
    .navbar-collapse.navbar-collapse.show {
        height: 100vh !important;
        margin-right: 49%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        display: block;
        background-color: rgb(255, 255, 255);
        z-index: 1000;
    }
    .dashboard-bl {
        border-bottom: none;
        padding-right: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
        display: inline-block;
        position: absolute;
        top: 9px;
        left: 13px;
    }
    .bg-menu-white {
        background-color: white;
        height: auto;
        line-height: auto;
    }

    .nav-link{
        padding: 0.5rem 1rem !important;
        padding-right: 0px !important;
    }
    .nav-item-ipad{
        position: absolute;
        bottom: 100px;
    }

    .dashboard-bl {
        border-bottom: 4px solid transparent;
        padding-right: 10px;
        padding-bottom: 8px;
        margin-bottom: 0px;
        display: inline-block;
        position: static;
        position: initial; 
        top: auto;
        left: auto;
    }

    .nav-span-ipad{
        margin-left: 7px;
    }
    .border-menu-hr {
        border-bottom: 1px solid #DEDEDE;
    }

    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 300px !important;
        top: 156px !important;
        z-index: 1000 !important;
    
    }
    

}


@media (min-device-width : 1201px){
    .timesheetTblGrid {
        height: 62vh !important;
    }
}


@media (min-device-width : 1400px){
    .timesheetTblGrid {
        height: 73vh !important;
    }
}


.dropdown-menu a{
    text-decoration: none;
}

#navigationMenu{
    display: block;
}

/* .k-widget{
    font-size: 16px;
} */

.k-grid-header {
  display: none!important;
}

.k-grid{
  color:#212529!important;
}

.k-grid-toolbar{
  background-color: #fff;
}

.k-grid {
    border-color: transparent;
    color: #656565;
    background-color: #fff;
    background-clip: padding-box;
}
.k-grid-content {
    width: 100%;
    min-height: 0;
    overflow: auto;
    overflow-x: auto;
    overflow-y: auto;
    position: relative;
    flex: 1 1;
}

.k-grid tr.k-alt {
    background-color: transparent;
}
.k-combobox .k-select {
    border-color: #2E4F79;
    color: #2E4F79;
}

.k-grid td {
    padding: 8px 6px;
    /* border-style: solid; */
    border-color: inherit;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none
  
}

.k-grid th{
    padding: 8px 6px;
    /* border-style: solid; */
    border-color: inherit;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none
}

.k-grid a.icon-clr-blue{
    color:#007bff;
}

.k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover {
    color: black;
    background-color: transparent;
}

.k-grid tbody tr.txt-clr-blue:hover{
    color: #007bff;
}

.k-grid tr {
    padding: 8px 12px;
    border-width: 0 0 1px 1px;
    white-space: nowrap;
    border: none;
}

.grn-bg-icon {
    display: inline-block;
    width: 33px;
    height: 33px;
    background-color: #187B87;
    border-radius: 100px;
    line-height: 33px;
    color: #fff;
    text-align: center;
    margin-left: 16px;
}

@media (min-width: 320px) and (max-width: 667px) {
    .k-widget{
        font-size: 12px;
    }

    .checkmark-R {
        position: absolute;
        height: 20px;
        width: 20px;
        background-color: #fff;
        position: absolute;
        top: -1px;
        left: -4px;
    
    }


}



.timesheetTblGrid {
    height: 400px;
}

.k-notification-success {
    border-color: transparent;
    color: #fff;
    background-color: #009292;
    height: 25px;
    font-size: 11px;
}

.k-dialog-titlebar {
    border-color: inherit;
    color: #fff;
    background-color: #009292;
    font-size: 14px;
}
.select-all .checkmark-R {
    top: -8px;
    left: 9px;
    border: 1px solid #009292;
    background-color: #fff;
}
.container-R .checkmark-R:after {
    left: 6px;
    top: 0px;
    width: 7px;
    height: 14px;
    border: solid #4987ec;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark-R {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #4987ec;
    border-radius: 2px;
}
.checkmark-R:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 0px;
    width: 7px;
    height: 14px;
    border: solid #009292;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.k-button:active,
.k-button.k-state-active {
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #009292;
    color: white;
    border: transparent;
}
.k-button:active,
.k-button.k-state-active:hover {
    color: white;
    background-color: #009292;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
    background-image: -webkit-linear-gradient(#009292, #009292);
    background-image: linear-gradient(#009292, #009292);
}

.k-button:hover {
    color: white;
    background-color: #009292;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
    background-image: -webkit-linear-gradient(#009292, #009292);
    background-image: linear-gradient(#009292, #009292);
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}
.dropdown-item:hover,
.dropdown-item:focus {
    color: white;
    text-decoration: none;
    background-color: #0c4c89;
}

.providerCls {
    font-size: 18px;
    color: #007bff;
}

.required:after {
    content: "*";
    font-weight: bold;
    color: red;
    padding-left: 2px;
    font-size: 0.8rem;
}

@media (min-width: 320px) and (max-width: 667px) {
    .providerCls {
        font-size: 15px;
        color: #007bff;
    }

    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 44px !important;
        top: 44px !important;
        z-index: 1000 !important;
    }

    .gridCheckbox {
        top: -8px;
        left: 80px;
    }

    .grainsboro-clr {
        color: #a3a3a3 !important;
    }
    .select-alll .checkmark-R {
        top: -3px;
        left: 10px;
        border: 1px solid #009292;
        background-color: #fff;
    }
    .select-alll .container-R .checkmark-R:after {
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #009292;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .container-R .checkmark-R:after {
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #4987ec;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .button-reject {
        background-color: #d21031;
        font-size: 12px;
    }
    .button-reject:hover {
        background-color: #c1122f;
    }
    .k-dialog-titlebar {
        border-color: inherit;
        color: #fff;
        background-color: #009292;
        font-size: 14px;
    }
    .k-window-title {
        margin: 0;
        font-size: 14px;
        line-height: 1.0526315789;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        cursor: default;
        flex: 1 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .k-notification-success {
        border-color: transparent;
        color: #fff;
        background-color: #009292;
        font-size: 11px;
    }

    .k-button:active,
    .k-button.k-state-active {
        box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
        background-color: #009292;
        color: white;
        border: transparent;
    }
    .k-button:active,
    .k-button.k-state-active:hover {
        color: white;
        background-color: #009292;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
        background-image: -webkit-linear-gradient(#009292, #009292);
        background-image: linear-gradient(#009292, #009292);
    }

    .k-button :hover {
        color: white;
        background-color: #009292;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
        background-image: -webkit-linear-gradient(#009292, #009292);
        background-image: linear-gradient(#009292, #009292);
    }

    .k-button:active,
    .k-button.k-state-active:hover {
        color: white;
        background-color: #009292;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
        background-image: -webkit-linear-gradient(#009292, #009292);
        background-image: linear-gradient(#009292, #009292);
    }

    .checkmark-R:after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #009292;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .timesheetTblGrid {
        height: 590px;
    }
}

@media (min-width: 1200px) {
    .providerCls {
        font-size: 16px;
        color: #007bff;
    }

    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 548px !important;
        top: 157px !important;
        z-index: 1000 !important;
    }
}

@media (min-width: 1400px) {
    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 852px !important;
        top: 157px !important;
        z-index: 1000 !important;
    }
}

.value-card-bg {
   background-color: #FBFAE4;
}

@media (min-width: 320px) and (max-width: 667px) {
   
   .serviceType{
    white-space: nowrap;
    width: 102px;
    max-width: 102px;
    overflow: hidden;
    text-overflow: ellipsis;
   }
   #textarea{
      color: #484848;
   }
   .grainsboro-clr {
      color: #A3A3A3 !important;
   }
}

.group-notification{
    
   align-items: flex-start;
   flex-wrap: wrap-reverse;
   right: 25px;
   top: 47px;
   z-index: 1000;

}

.grainsboro-clr {
   color: #A3A3A3 !important;
}

::-webkit-input-placeholder {
   color: #A3A3A3 !important;
   font-style: italic !important;
}

::-moz-placeholder {
   color: #A3A3A3 !important;
   font-style: italic !important;
}

::-ms-input-placeholder {
   color: #A3A3A3 !important;
   font-style: italic !important;
}

::placeholder {
   color: #A3A3A3 !important;
   font-style: italic !important;
}

@media print {

   
   th{
      background-color: #2999a6;
      color:#2999a6;
   }

   #content,#page {
   width: 100%;
   margin: 0;
   float: none;
   }
   
   header nav, footer {
   display: none;
   }
   
   
   .navbar-toggler-icon,.signOut,.hidePrint {
   display: none;
   }
   
   .justify-content-end{   
   display: none;}

   .active-icon{
   display: none;
   }

   /* .table > tbody > tr > td.value-card-bg {
      background-color: #FBFAE4 !important;
  }
  .table > thead > tr > th.value-card-bg {
   background-color: #FBFAE4 !important;
} */
.table > tbody > tr > td.value-card-bg {
   background-color: #FBFAE4 !important;
}

#print > .table td, .table th {
   background-color: #2999A6 !important;
   color:#fff!important; 
}


   /* tr.BgListHeading {
      background-color: #FBFAE4 !important;
      -webkit-print-color-adjust: exact;
      display: block; 
  } */
   
   @page {
   margin: 2cm;
   
   }
   
   }
