.timesheetTblGrid {
    height: 400px;
}

.k-notification-success {
    border-color: transparent;
    color: #fff;
    background-color: #009292;
    height: 25px;
    font-size: 11px;
}

.k-dialog-titlebar {
    border-color: inherit;
    color: #fff;
    background-color: #009292;
    font-size: 14px;
}
.select-all .checkmark-R {
    top: -8px;
    left: 9px;
    border: 1px solid #009292;
    background-color: #fff;
}
.container-R .checkmark-R:after {
    left: 6px;
    top: 0px;
    width: 7px;
    height: 14px;
    border: solid #4987ec;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark-R {
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #4987ec;
    border-radius: 2px;
}
.checkmark-R:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 0px;
    width: 7px;
    height: 14px;
    border: solid #009292;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.k-button:active,
.k-button.k-state-active {
    box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #009292;
    color: white;
    border: transparent;
}
.k-button:active,
.k-button.k-state-active:hover {
    color: white;
    background-color: #009292;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
    background-image: -webkit-linear-gradient(#009292, #009292);
    background-image: linear-gradient(#009292, #009292);
}

.k-button:hover {
    color: white;
    background-color: #009292;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
    background-image: -webkit-linear-gradient(#009292, #009292);
    background-image: linear-gradient(#009292, #009292);
}
a:hover {
    color: #0056b3;
    text-decoration: none;
}
.dropdown-item:hover,
.dropdown-item:focus {
    color: white;
    text-decoration: none;
    background-color: #0c4c89;
}

.providerCls {
    font-size: 18px;
    color: #007bff;
}

.required:after {
    content: "*";
    font-weight: bold;
    color: red;
    padding-left: 2px;
    font-size: 0.8rem;
}

@media (min-width: 320px) and (max-width: 667px) {
    .providerCls {
        font-size: 15px;
        color: #007bff;
    }

    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 44px !important;
        top: 44px !important;
        z-index: 1000 !important;
    }

    .gridCheckbox {
        top: -8px;
        left: 80px;
    }

    .grainsboro-clr {
        color: #a3a3a3 !important;
    }
    .select-alll .checkmark-R {
        top: -3px;
        left: 10px;
        border: 1px solid #009292;
        background-color: #fff;
    }
    .select-alll .container-R .checkmark-R:after {
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #009292;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .container-R .checkmark-R:after {
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #4987ec;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .button-reject {
        background-color: #d21031;
        font-size: 12px;
    }
    .button-reject:hover {
        background-color: #c1122f;
    }
    .k-dialog-titlebar {
        border-color: inherit;
        color: #fff;
        background-color: #009292;
        font-size: 14px;
    }
    .k-window-title {
        margin: 0;
        font-size: 14px;
        line-height: 1.0526315789;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        cursor: default;
        flex: 1 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .k-notification-success {
        border-color: transparent;
        color: #fff;
        background-color: #009292;
        font-size: 11px;
    }

    .k-button:active,
    .k-button.k-state-active {
        box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06);
        background-color: #009292;
        color: white;
        border: transparent;
    }
    .k-button:active,
    .k-button.k-state-active:hover {
        color: white;
        background-color: #009292;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
        background-image: -webkit-linear-gradient(#009292, #009292);
        background-image: linear-gradient(#009292, #009292);
    }

    .k-button :hover {
        color: white;
        background-color: #009292;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
        background-image: -webkit-linear-gradient(#009292, #009292);
        background-image: linear-gradient(#009292, #009292);
    }

    .k-button:active,
    .k-button.k-state-active:hover {
        color: white;
        background-color: #009292;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#009292), to(#009292));
        background-image: -webkit-linear-gradient(#009292, #009292);
        background-image: linear-gradient(#009292, #009292);
    }

    .checkmark-R:after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #009292;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .timesheetTblGrid {
        height: 590px;
    }
}

@media (min-width: 1200px) {
    .providerCls {
        font-size: 16px;
        color: #007bff;
    }

    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 548px !important;
        top: 157px !important;
        z-index: 1000 !important;
    }
}

@media (min-width: 1400px) {
    .group-notification {
        align-items: flex-start;
        flex-wrap: wrap-reverse;
        right: 852px !important;
        top: 157px !important;
        z-index: 1000 !important;
    }
}
