.value-card-bg {
   background-color: #FBFAE4;
}

@media (min-width: 320px) and (max-width: 667px) {
   
   .serviceType{
    white-space: nowrap;
    width: 102px;
    max-width: 102px;
    overflow: hidden;
    text-overflow: ellipsis;
   }
   #textarea{
      color: #484848;
   }
   .grainsboro-clr {
      color: #A3A3A3 !important;
   }
}

.group-notification{
    
   align-items: flex-start;
   flex-wrap: wrap-reverse;
   right: 25px;
   top: 47px;
   z-index: 1000;

}

.grainsboro-clr {
   color: #A3A3A3 !important;
}

::placeholder {
   color: #A3A3A3 !important;
   font-style: italic !important;
}

@media print {

   
   th{
      background-color: #2999a6;
      color:#2999a6;
   }

   #content,#page {
   width: 100%;
   margin: 0;
   float: none;
   }
   
   header nav, footer {
   display: none;
   }
   
   
   .navbar-toggler-icon,.signOut,.hidePrint {
   display: none;
   }
   
   .justify-content-end{   
   display: none;}

   .active-icon{
   display: none;
   }

   /* .table > tbody > tr > td.value-card-bg {
      background-color: #FBFAE4 !important;
  }
  .table > thead > tr > th.value-card-bg {
   background-color: #FBFAE4 !important;
} */
.table > tbody > tr > td.value-card-bg {
   background-color: #FBFAE4 !important;
}

#print > .table td, .table th {
   background-color: #2999A6 !important;
   color:#fff!important; 
}


   /* tr.BgListHeading {
      background-color: #FBFAE4 !important;
      -webkit-print-color-adjust: exact;
      display: block; 
  } */
   
   @page {
   margin: 2cm;
   
   }
   
   }