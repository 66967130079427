.errorMessage {
    color: white;
    font-size: 12px;
    /* margin-top: 26px; */
    /* background-color: white; */
}


@media (min-width: 320px) and (max-width: 667px) {
    .rememberMeCheck{
        top: -1px;
        left: -6px;
    }
    /* .k-loading-image {
        background-image: url('../../assets/images/805.gif');
        background-repeat: no-repeat;
        background-size: 50px 50px;
        z-index: 15000000 !important;
    } */
} 
